import React, { FC } from 'react';
import { Box, Paragraph } from 'grommet';
import styled, { css } from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import Link from './link';
import { IImage } from '../utils/componentInterfaces';
import Block from './block';
import bgImage from '../images/quoteBg.svg';
import bgImageW from '../images/quoteBgW.svg';

const StyledBlock = styled(Block)`
    .block {
        position: relative;
        background-image: url('${bgImage}');
        background-position: top 60px left;
        background-repeat: no-repeat;
        background-size: 20% auto;
    }
`;
const StyledQuoteParagraph = styled(Paragraph)`
    background-image: url('${bgImageW}');
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 16% auto;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 40px;
    min-height: 52px;
`;

const StyledQuoteBlock = styled(Box)`
    ${({ theme }) => css`
        padding: ${theme.global.edgeSize.medium} 0 0 10%;
    `}
`;
interface Props {
    author: string;
    content: string;
    quoteText: string;
    image: IImage;
}

const QuoteText: FC<Props> = ({ author, quoteText, image }) => (
    <StyledBlock className="light-1" direction="row-responsive">
        <StyledQuoteBlock justify="center">
            <Paragraph fill size="large" color="accent-2">
                {quoteText}
            </Paragraph>
        </StyledQuoteBlock>
        {image && image.localFile && image.localFile.childImageSharp && (
            <Box width={{ max: '400px' }} basis="100%" flex={{ shrink: 0, grow: 1 }}>
                <Link to="/testimonials">
                    {' '}
                    <GatsbyImage
                        image={image.localFile.childImageSharp.gatsbyImageData}
                        alt={image?.altText || ''}
                        loading="eager"
                    />
                </Link>

                <StyledQuoteParagraph size="xlarge">
                    <em>{author}</em>
                    <Link to="/testimonials"> ...read more</Link>
                </StyledQuoteParagraph>
            </Box>
        )}
    </StyledBlock>
);

export default QuoteText;
